import React from 'react';
import { map } from 'lodash';
import {
  Collapse, Box, Link, Accordion, AccordionItem, AccordionIcon, AccordionButton, AccordionPanel
} from '@chakra-ui/react';

const FAQ_LIST = [
  {
    question: `What’s a prop bet?`,
    answer: `A prop bet (short for “proposition bet”) is a bet that usually has nothing to do with the final outcome of an event. It’s phrased in the form of a question, like “Will at least one player score 40+ points in this game?” or "Who will win the award for Best Picture?”.`
  },
  {
    question: `What’s a prop bets game?`,
    answer: `A prop bets game is a questionnaire with a list of prop bets. You send it to a group of friends, have everyone pay an entry fee, and everyone fills out the questionnaire. After the game, you go and tally up all the questionnaires. Whoever got the most questions right is crowned the winner, and wins money! You can also split the awards among the top entries. For example, 1st place gets 60%, 2nd place gets 30%, and 3rd place gets 10%. SB Prop Bets is a free service that makes it easy to create your own prop bets game.`,
  },
  {
    question: `Someone sent me a group link, what do I do now?`,
    answer: `Click on "Submit Entry", and make your prop bet picks. If your group has an entry fee, make sure to pay it to the group creator as per their instructions. That's it! We’ll be updating the scores live as the game goes on, so keep checking your group link to see how you and your friends are doing.`,
  },
  {
    question: `I already submitted picks in another group. Can I copy my picks to a different group?`,
    answer: `Yes! If you use the same email when submitting picks in the new group, we'll give you the option to copy your picks from a previous group.`,
  },
  {
    question: `When making a group, how do I decide what questions to use for the prop bets game?`,
    answer: `You don't have to decide - we have already pre-selected questions for the Super Bowl. It doesn't take more than 30 seconds to create a group.`,
  },
  {
    question: `Do I have to have an entry fee?`,
    answer: `Nope. You can play a prop bets game just for bragging rights, it’s fun either way.`,
  },
  {
    question: `How do people pay their entry fee?`,
    answer: `We don’t process any entry fees on our platform, but you can provide instructions for how people should pay when you create your group. People often use Venmo or PayPal.`,
  },
  {
    question: `How do I send this to my friends?`,
    answer: `Send out your unique group link, and your friends can submit entries there.`,
  },
  {
    question: `Is there a limit to the number of people in a group?`,
    answer: `No, the more the merrier.`,
  },
  {
    question: `After I create the group and send it out, do I need to do anything?`,
    answer: `Not really! We’ll be updating the scores live as the event goes on, so keep checking your group link to see how you and your friends are doing. Leave the tallying up to us, and once the game is over, make sure to send out the awards to the top entries.`,
  },
  {
    question: `Why can't I see anyone's entries?`,
    answer: `Entries will be made available on your group's unique link once the event starts, so make sure to check back in then.`,
  },
  {
    question: `Can I edit my entry before the event starts?`,
    answer: `For now, there is no way to edit your entry - make sure to double-check your picks! We're working on making this available for future events.`,
  },
  {
    question: `Until when can people submit entries?`,
    answer: `Your group page shows when it will stop accepting entries. It’s also at this time when you can see what other people put for their answers, and the totals for each question.`,
  },
  {
    question: `Can I create more than one group?`,
    answer: `Yes! Create as many as you want.`,
  },
  {
    question: `Can I create a custom prop bets game, or change the prop bets for this event?`,
    answer: `Not yet, but we're considering adding that functionality. For now, click on "Request Event" above to inquire about a custom event!`,
  },
  {
    question: `Why’d you make this?`,
    answer: `The lack of in-person social events during the peak COVID-19 years had us really bummed out - so we figured we could at least try to bring some of the fun virtually in the form of prop bets games. We were unable to find anything online that did what we wanted, so we decided to build it out so everyone else can use it too!`,
  },
];


export const Faq = () => {
  return (
    <Collapse startingHeight={0} in={true}>
      <Accordion
        allowMultiple
        allowToggle
        maxWidth={["lg", "2xl"]}
        px="10px"
      >
        {map(FAQ_LIST, (faq, index) => {
          return (
            <AccordionItem key={index}>
              <AccordionButton
                _focus={{
                  boxShadow: "none !important"
                }}
                _expanded={{
                  fontWeight: "bold"
                }}
              >
                <Box flex="1" textAlign="left">
                  {faq.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                {faq.answer}
              </AccordionPanel>
            </AccordionItem>
          )
        })}
        <AccordionItem key={'question_feedback'}>
          <AccordionButton
            _focus={{
              boxShadow: "none !important"
            }}
            _expanded={{
              fontWeight: "bold"
            }}
          >
            <Box flex="1" textAlign="left">
              I have a question and/or feedback!
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            We’d love to hear it, email us at <Link target="_blank" color="brandColorScheme.300" href="mailto:hello@sbpropbets.com">hello@sbpropbets.com</Link>.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Collapse>
  );
}
