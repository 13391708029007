import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { forEach, includes, isEmpty, map, some, toLower, values, every, isNumber } from 'lodash';
import {
  Heading, Text, Stack, Center, useDisclosure, useBreakpointValue, Spinner, Box, Wrap,
  WrapItem, Badge, Collapse, Link, Image
} from '@chakra-ui/react';

import { fetchGroupByUrlId, fetchQuestionGroupById, fetchQuestionsByQuestionGroupId, fetchEntriesByGroupId } from '../api/Api';
import useInterval from '../hooks/UseInterval';
import usePageVisibility from '../hooks/UsePageVisibility';
import { ThemeContext } from '../contexts/ThemeState';
import { OnGroupCreateModal } from '../components/OnGroupCreateModal';
import { OnSubmitEntryModal } from '../components/OnSubmitEntryModal';
import { SubmitEntryDrawer } from '../containers/SubmitEntryDrawer';
import { GroupEntries } from '../containers/GroupEntries';
import { StyledButton } from '../styled-components/StyledButton';
import { IEntry, IGroup, IQuestionDict, IQuestionGroup, QuestionTypes } from '../interfaces/Models';
import { formatDate, TROPHY_EMOJIS } from '../utils/MiscUtils';
import SponsoredByTuskBadge from '../static/sponsored-by-tusk-badge.svg';

interface ILocationState {
  showOnGroupCreateModal?: boolean;
}

interface IRouteParams {
  [key: string]: string | undefined;
  id: string;
}

const QUESTIONS_REFRESH_INTERVAL = 10000;
const CHECK_ENTRY_ALLOWED_INTERVAL = 10000;

export const GroupPage = () => {
  const params = useParams<IRouteParams>();
  const location = useLocation();
  const groupUrlId = params.id;
  const navigate = useNavigate();
  const isPageVisible = usePageVisibility();

  const { updateTheme } = useContext(ThemeContext);

  const entryButtonSize = useBreakpointValue(["md", "lg"]);
  const groupButtonSize = useBreakpointValue(["xs", "sm"]);

  const { isOpen: isGroupCreateModalOpen, onOpen: onGroupCreateModalOpen, onClose: onGroupCreateModalClose } = useDisclosure();
  const { isOpen: isSubmitEntryModalOpen, onOpen: onSubmitEntryModalOpen, onClose: onSubmitEntryModalClose } = useDisclosure();

  useEffect(() => {
    const state = location.state as ILocationState;
    if (state?.showOnGroupCreateModal) {
      onGroupCreateModalOpen();
      const currentPath = `${location.pathname}${location.search}`;
      navigate(currentPath, { replace: true });
    }
  }, [location.state]);

  // TODO: maybe use https://www.davidhu.io/react-spinners/
  const [isLoading, setIsLoading] = useState(true);
  const [hasApiError, setHasApiError] = useState(false);

  const [group, setGroup] = useState<IGroup>();
  const [questionGroup, setQuestionGroup] = useState<IQuestionGroup>();
  const [questionDict, setQuestionDict] = useState<IQuestionDict>();
  const [entries, setEntries] = useState<IEntry[]>();
  const [isEntryAllowed, setIsEntryAllowed] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [isEventOver, setIsEventOver] = useState(false);
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerCLose } = useDisclosure();

  const isEntryNameUnique = (name: string) => {
    const allNames = map(entries, (entry) => toLower(entry.name));
    return !includes(allNames, toLower(name));
  }

  const isEntryEmailUnique = (email: string) => {
    const allEmails = map(entries, (entry) => toLower(entry.email));
    return !includes(allEmails, toLower(email));
  }

  const newEntryCallback = (newEntry: IEntry) => {
    const updatedEntries = [...entries, newEntry];
    setEntries(updatedEntries);
    if (newEntry.email !== group.email) {
      onSubmitEntryModalOpen();
    }
  }

  const onHomePageClick = () => {
    window.open(`${window.location.origin}/home`, '_blank');
  }

  useEffect(() => {
    (async () => {
      if (isPageVisible && !isEmpty(questionDict) && !isEventOver) {
        const { data: questionsData, hasError: fetchQuestionsError } = await fetchQuestionsByQuestionGroupId(group.questionGroupId);
        if (!fetchQuestionsError) {
          const questionDict = {};
          forEach(questionsData, (question) => {
            questionDict[question.id] = { ...question };
          });
          setQuestionDict(questionDict);
        }
      }
    })();
  }, [isPageVisible, isEventOver]);

  // Poll for updates to questions when page is visible and entries are not allowed
  useInterval(async () => {
    if (group?.questionGroupId) {
      const { data: questionsData, hasError: fetchQuestionsError } = await fetchQuestionsByQuestionGroupId(group.questionGroupId);
      if (!fetchQuestionsError) {
        const questionDict = {};
        forEach(questionsData, (question) => {
          questionDict[question.id] = { ...question };
        })
        setQuestionDict(questionDict);
      }
    }
  }, (isPageVisible && !isEntryAllowed && !isEventOver) ? QUESTIONS_REFRESH_INTERVAL : null);

  // Fetch initial data about the group
  useEffect(() => {
    // Only fetch data if groupUrlId has changed from current group urlId
    if (groupUrlId === group?.urlId) {
      return;
    }

    (async () => {
      setIsLoading(true);
  
      const searchParams = queryString.parse(location.search);
      const password = searchParams?.code as string;
      if (!password) {
        setHasApiError(true);
        setIsLoading(false);
      }

      const { data: groupData, hasError: fetchGroupError } = await fetchGroupByUrlId(groupUrlId, password);
      setGroup(groupData);

      const groupId = groupData.id;
      const groupPassword = groupData.password;
      const questionGroupId = groupData.questionGroupId;

      const { data: questionGroupData, hasError: fetchQuestionGroupError } = await fetchQuestionGroupById(questionGroupId);
      setQuestionGroup(questionGroupData);

      const { data: questionsData, hasError: fetchQuestionsError } = await fetchQuestionsByQuestionGroupId(questionGroupId);
      const questionDict = {};
      forEach(questionsData, (question) => {
        questionDict[question.id] = { ...question };
      })
      setQuestionDict(questionDict);

      const { data: entriesData, hasError: fetchEntriesError } = await fetchEntriesByGroupId(groupId, groupPassword);
      setEntries(entriesData);

      setHasApiError(some([fetchGroupError, fetchQuestionGroupError, fetchQuestionsError, fetchEntriesError], Boolean));
      setIsLoading(false);
    })();
  }, [groupUrlId, location.search]);

  // Set document title with the group name
  useEffect(() => {
    if (group?.name) {
      document.title = `${group.name} - SB Prop Bets`;
    }
  }, [group?.name]);

  // Set the color schemes for this group in the theme
  useEffect(() => {
    const colorSchemeDict: any = {};
    map(values(questionDict), (question) => {
      if (question.type === QuestionTypes.Choice) {
        map(question.choices, (choice) => {
          if (!isEmpty(choice.colorScheme)) {
            colorSchemeDict[`${question.id}_${choice.id}`] = choice.colorScheme!; 
          }
        });
      } else if (question.type === QuestionTypes.Number) {
        if (!isEmpty(question.numberColorScheme)) {
          if (!isEmpty(question.numberColorScheme)) {
            colorSchemeDict[`${question.id}`] = question.numberColorScheme!; 
          }
        }
      }
    })

    updateTheme({
      colors: colorSchemeDict
    });

    // TODO: Return a tear down function that removes these colors from the theme
  }, [questionDict]);

  // Set if entries are allowed
  useEffect(() => {
    if (questionGroup?.acceptEntriesTil) {
      const newIsEntryAllowed = new Date() <= new Date(questionGroup.acceptEntriesTil);
      setIsEntryAllowed(newIsEntryAllowed);
      setShowInfo(newIsEntryAllowed);
    }
  }, [questionGroup?.acceptEntriesTil]);

  // Poll to see if current time is past when entries are allowed
  useInterval(async () => {
    const newIsEntryAllowed = new Date() <= new Date(questionGroup.acceptEntriesTil);
    setIsEntryAllowed(newIsEntryAllowed);
    // If entries switch to not being allowed, fetch all entries again
    if (!newIsEntryAllowed) {
      const { data: entriesData, hasError: fetchEntriesError } = await fetchEntriesByGroupId(group.id, group.password);
      if (!fetchEntriesError) {
        setEntries(entriesData);
      }
    }
  }, (isPageVisible && questionGroup?.acceptEntriesTil && isEntryAllowed) ? CHECK_ENTRY_ALLOWED_INTERVAL : null);

  // Calculates if event is over (if all questions have a result)
  useEffect(() => {
    const newIsEventOver = every(values(questionDict), (question) => {
      if (question.type === QuestionTypes.Choice) {
        return Boolean(question.choiceResultId);
      } else if (question.type === QuestionTypes.Number) {
        return isNumber(question.numberResult);
      }
    });
    setIsEventOver(newIsEventOver);
  }, [questionDict]);

  return (
    <>
      <Stack spacing={3} align="center">
        {(isLoading || hasApiError) &&
          <>
            <Center>
              <Heading fontSize={["1xl", "2xl"]} px="10px" mt={["54px", "76px"]} textAlign="center">Prop Bets Game</Heading>
            </Center>
            <Center py={["2px", "12px"]}>
                <Image 
                  src={SponsoredByTuskBadge} 
                  alt="Sponsored by Tusk" 
                  onClick={() => window.open('https://www.usetusk.ai/?utm_source=sbpropbets&utm_content=grouppage', '_blank')}
                  cursor="pointer"
                  width={["160px", "200px"]}
                  height="auto"
                  transition="all 0.2s"
                  _hover={{
                    opacity: 0.9
                  }}
                />
              </Center>
            {hasApiError ?
              <Center>
                <Heading fontSize={["md", "lg"]} px="10px" color="red.300">Looks like there was a problem finding your group. Are you sure you have the right url?</Heading>
              </Center>
              :
              <Spinner
                thickness="5px"
                speed="1s"
                size="xl"
              />
            }
          </>
        }
        {!isLoading && !hasApiError &&
          <>
            <Stack align="center">
              <Heading fontSize={["2xl", "4xl"]} px="10px" pt={["20px", "30px"]} textAlign="center">{group.name}</Heading>
              <Heading fontSize={["1xl", "2xl"]} px="10px" textAlign="center">{questionGroup.name} Prop Bets Game</Heading>
              <Center py={["2px", "12px"]}>
                <Image 
                  src={SponsoredByTuskBadge} 
                  alt="Sponsored by Tusk" 
                  onClick={() => window.open('https://www.usetusk.ai/?utm_source=sbpropbets&utm_content=grouppage', '_blank')}
                  cursor="pointer"
                  width={["160px", "200px"]}
                  height="auto"
                  transition="all 0.2s"
                  _hover={{
                    opacity: 0.9
                  }}
                />
              </Center>
              <Box>
                {isEntryAllowed ?
                  <>
                    <Heading as="span" fontSize={["md", "lg"]} px="10px" pr="0px">Accepting entries til </Heading>
                    <Heading as="span" fontSize={["md", "lg"]} px="10px" pl="0px" color="brandColorScheme.300">{formatDate(questionGroup.acceptEntriesTil)}</Heading>
                  </>
                  :
                  <>
                    {isEventOver ?
                      <Heading fontSize={["md", "lg"]} px="10px" color="gray.500">Results are finalized</Heading>
                      :
                      <Heading fontSize={["md", "lg"]} px="10px" color="red.300">Event is in progress</Heading>
                    }
                  </>
                }
              </Box>
              <Collapse startingHeight={0} in={showInfo}>
                <Box maxWidth={["lg", "2xl"]} fontSize={["sm", "lg"]} px={["20px", "10px"]}>
                  {group.notes &&
                    <Text pt={["4px", "8px"]}>{group.notes}</Text>
                  }
                  {isEntryAllowed &&
                    <Box pt={["4px", "8px"]}>
                      <Text as="span" fontWeight="bold" color="brandColorScheme.300">Instructions: </Text>
                      <Text as="span">Submit your entry by clicking the button below (no edits after submitting). Once the event starts, everyone's entries will be made visible on this link. Rankings will be updated immediately as the event goes on, so make sure to come back to see live updates on everyone's picks!</Text>
                    </Box>
                  }
                  {questionGroup.infoText &&
                    <Box pt={["4px", "8px"]}>
                      <Text as="span" fontWeight="bold" color="brandColorScheme.300">Event Info: </Text>
                      <Text as="span">{questionGroup.infoText}</Text>
                    </Box>
                  }
                  {!!group.entryFee &&
                    <>
                      <Box pt={["4px", "8px"]}>
                        <Text as="span" fontWeight="bold" color="brandColorScheme.300">The entry fee is ${group.entryFee}. </Text>
                        {group.entryFeeInstructions &&
                          <Text as="span">{group.entryFeeInstructions}</Text>
                        }
                      </Box>
                      <Wrap pt={["4px", "8px"]}>
                        <WrapItem>
                          <Text fontWeight="bold" color="brandColorScheme.300" pt={["1px", "2px"]}>Award Distribution</Text>
                        </WrapItem>
                        <WrapItem>
                          <Text>
                            {map(group.awardDistributions, (percent, index) => {
                              return (
                                <span key={index}>
                                  <Text as="span" pl={["4px", "16px"]}>
                                    {TROPHY_EMOJIS[index]}
                                  </Text>
                                  <Badge
                                    colorScheme="gray"
                                    fontSize={["xs", "lg"]}
                                    ml={["2px", "8px"]}
                                  >
                                    {percent}%
                                  </Badge>
                                </span>
                              );
                            })}
                          </Text>
                        </WrapItem>
                      </Wrap>
                    </>
                  }
                  <Box pt={["8px", "12px"]}>
                    {/* TODO: Use Environment.tsx to determine the url here */}
                    <Text as="span" fontWeight="bold" color="brandColorScheme.300">Confused? </Text><Text as="span">Check out the FAQ </Text>
                    <Link textDecoration="underline !important" target="_blank" href="https://sbpropbets.com/home/faq">here</Link>
                  </Box>
                </Box>
              </Collapse>
              <Box>
                <StyledButton
                  size={groupButtonSize}
                  onClick={() => setShowInfo(!showInfo)}
                  variant="ghost"
                  mt={["4px", "8px"]}
                >
                  {showInfo ? 'Close Info' : 'Show Info'}
                </StyledButton>
              </Box>
            </Stack>
            <GroupEntries
              entries={entries}
              showResults={!isEntryAllowed}
              isEventOver={isEventOver}
              questionGroup={questionGroup}
              questionDict={questionDict}
            />
            <Box position="fixed" bottom="0px" pt={["10px", "20px"]} pb={["20px", "40px"]}>
              <Stack
                spacing={4}
                align="center"
              >
                {isEntryAllowed &&
                  <StyledButton
                    colorScheme="brandColorScheme"
                    size={entryButtonSize}
                    onClick={onDrawerOpen}
                  >
                    Submit Entry 🎲
                  </StyledButton>
                }
                <SubmitEntryDrawer
                  isOpen={isDrawerOpen}
                  onClose={onDrawerCLose}
                  group={group}
                  questionGroup={questionGroup}
                  questionDict={questionDict}
                  isEntryNameUnique={isEntryNameUnique}
                  isEntryEmailUnique={isEntryEmailUnique}
                  newEntryCallback={newEntryCallback}
                />
                <StyledButton
                  size={groupButtonSize}
                  onClick={onHomePageClick}
                >
                  Home Page
                </StyledButton>
              </Stack>
            </Box>
            <OnGroupCreateModal
              isOpen={isGroupCreateModalOpen}
              onClose={onGroupCreateModalClose}
              groupUrl={window.location.href}
            />
            <OnSubmitEntryModal
              isOpen={isSubmitEntryModalOpen}
              onClose={onSubmitEntryModalClose}
              questionGroup={questionGroup}
            />
          </>
        }
      </Stack>
    </>
  );
};
