import React, { useContext } from 'react';
import {
  Route,
  BrowserRouter,
  Routes,
  redirect,
  Navigate
} from 'react-router-dom';
import { ChakraProvider, Box, ColorModeProvider } from '@chakra-ui/react';

import { ThemeContext } from './contexts/ThemeState';
import { Fonts } from './components/Fonts';
import { CreateGroupPage } from './pages/CreateGroupPage';
import { GroupPage } from './pages/GroupPage';
import { HomePage } from './pages/HomePage';
import { HomePage as AdminHomePage } from './pages/admin/HomePage';
import { QuestionPage as AdminQuestionPage } from './pages/admin/QuestionPage';
import { QuestionGroupPage as AdminQuestionGroupPage } from './pages/admin/QuestionGroupPage';
import { CreateQuestionGroupPage } from './pages/admin/CreateQuestionGroupPage';

export const InnerApp = () => {
  const { theme } = useContext(ThemeContext);


  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider options={{
        initialColorMode: "dark",
        useSystemColorMode: false,
      }}>
        <Fonts />
        <Box bg="background" minHeight="100vh">
          <BrowserRouter>
            <Routes>
              <Route path="/create-group/:questionGroupId" element={<CreateGroupPage />} />
              <Route path="/group/:id" element={<GroupPage />} />
              <Route path="/home/:subpage?" element={<HomePage />} />
              <Route
                path="/sblv-survey"
                element={<Navigate to="https://docs.google.com/forms/d/e/1FAIpQLSe5Ik3DBGvUxVSiSH5orzg9p3Ba2uyyMbAH3WjsnxTZ-kaQrA/viewform?usp=sf_link" replace />}
              />
              <Route path="/admin" element={<AdminHomePage />} />
              <Route path="/admin/create-question-group" element={<CreateQuestionGroupPage />} />
              <Route path="/admin/question/:questionId" element={<AdminQuestionPage />} />
              <Route path="/admin/question-group/:questionGroupId" element={<AdminQuestionGroupPage />} />
              <Route path="/" element={<Navigate to="/home" />} />
            </Routes>
          </BrowserRouter>
        </Box>
      </ColorModeProvider>
    </ChakraProvider> 
  );
};
