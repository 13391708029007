import React, { useState } from 'react';
import { trim } from 'lodash';
import {
  Stack, FormLabel, FormControl, useBreakpointValue,
  FormHelperText, VStack, Center, Link, useToast
} from '@chakra-ui/react';

import { submitEventRequest } from '../api/Api';
import { StyledInput } from '../styled-components/StyledInput';
import { StyledButton } from '../styled-components/StyledButton';
import { StyledTextarea } from '../styled-components/StyledTextarea';
import { emailRegex } from '../utils/InputUtils';

export const RequestEventForm = () => {
  const toast = useToast();
  
  const inputSize = useBreakpointValue(["sm", "md"]);
  const formControlWidth = ["sm", "xl"];

  const [email, setEmail] = useState("");
  const isEmailInvalid = email && !emailRegex.test(email);

  const [eventInfo, setEventInfo] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);

  const isSubmitDisabled = Boolean(isEmailInvalid || !eventInfo);

  const onSubmit = async () => {
    setIsSubmitting(true);

    const { hasError } = await submitEventRequest(trim(email), eventInfo);

    if (hasError) {
      setIsSubmitting(false);
      setHasApiError(true);
      return;
    }

    toast({
      description: "Submitted request! We'll be in touch.",
      status: "success",
      isClosable: true,
    });
    setEmail('');
    setEventInfo('');
    setIsSubmitting(false);
    setHasApiError(false);
  }

  return (
    <Stack spacing={[3, 5]} width="100%" align="center">
      <FormControl maxWidth={formControlWidth} px="10px">
        <FormLabel>Email</FormLabel>
        <StyledInput
          colorScheme="gray"
          variant="filled"
          size={inputSize}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          isInvalid={isEmailInvalid}
        />
        {isEmailInvalid && 
          <FormHelperText color="red.300">Please enter a valid email.</FormHelperText>
        }
      </FormControl>
      <FormControl maxWidth={formControlWidth} px="10px">
        <FormLabel>Event Info</FormLabel>
        <StyledTextarea
          height={["5.4rem", "5.65rem"]} 
          colorScheme="gray"
          variant="filled"
          size={inputSize}
          placeholder="Tell us a little about the event you would like for SB Prop Bets to host."
          value={eventInfo}
          onChange={(event) => setEventInfo(event.target.value)}
        />
      </FormControl>
      <FormControl maxWidth={formControlWidth} px="10px">
        <Center>
          <VStack pt={["10px", "20px"]}>
            <StyledButton
              colorScheme="brandColorScheme"
              size={inputSize}
              isDisabled={isSubmitDisabled}
              isLoading={isSubmitting}
              loadingText={"Submit Request"}
              onClick={onSubmit}
            >
              Submit Request
            </StyledButton>
            {hasApiError &&
              <FormHelperText px="10px" color="red.300">Looks like there was an error submitting your event request. Try again! If you continue to have issues please email <Link color="brandColorScheme.300" href="mailto:hello@sbpropbets.com">hello@sbpropbets.com</Link>.</FormHelperText>
            }
          </VStack>
        </Center>
      </FormControl>
    </Stack>
  );
};
