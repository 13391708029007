import React from 'react';
import {
  Modal, ModalContent, ModalHeader, ModalCloseButton, UseDisclosureProps, ModalOverlay, ModalBody,
  ModalFooter, Text, DarkMode, useBreakpointValue, Stack
} from '@chakra-ui/react';

import { IQuestionGroup } from '../interfaces/Models';
import { StyledButton } from '../styled-components/StyledButton';

interface IProps extends UseDisclosureProps {
  questionGroup: IQuestionGroup;
}

export const OnSubmitEntryModal = (props: IProps) => {
  const { isOpen, onClose, questionGroup } = props;

  const modalSize = useBreakpointValue(["xs", "md"]);

  const onCreateGroupClick = () => {
    window.open(`${window.location.origin}/create-group/${questionGroup.id}`, '_blank');
  };

  return (
    <DarkMode>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={modalSize}
      >
        <ModalOverlay />
        <ModalContent backgroundColor={"background"}>
          <ModalHeader>Your picks are in!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={[2, 4]}>
              <Text>
                Once the Super Bowl starts, this page will update with live rankings and everyone's picks.
              </Text>
              <Text>
                Have other friends you want to play with? Create your own group for free.
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <StyledButton
              mr={3}
              colorScheme="brandColorScheme"
              onClick={onCreateGroupClick}
            >
              Create Free Group
            </StyledButton>
            <StyledButton
              colorScheme="brandColorScheme"
              onClick={onClose}
              variant="ghost"
            >
              Close
            </StyledButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkMode>
  );
};
