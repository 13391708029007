import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Heading, Center, Stack, Text, useDisclosure, useBreakpointValue } from '@chakra-ui/react';

import { fetchQuestionGroupById } from '../api/Api';
import { CreateGroupForm } from '../containers/CreateGroupForm';
import { QuestionGroupPreviewModal } from '../components/QuestionGroupPreviewModal';
import { StyledButton } from '../styled-components/StyledButton';
import { IQuestionGroup } from '../interfaces/Models';

interface IRouteParams {
  [key: string]: string | undefined;
  questionGroupId: string;
}

export const CreateGroupPage = () => {
  const params = useParams<IRouteParams>();
  const questionGroupId = params.questionGroupId;

  const previewQuestionsButtonSize = useBreakpointValue(["sm", "md"]);

  const [questionGroup, setQuestionGroup] = useState<IQuestionGroup>();

  useEffect(() => {
    (async () => {
      if (questionGroupId) {
        const { data: questionGroupData, hasError: fetchQuestionGroupError } = await fetchQuestionGroupById(questionGroupId);
        setQuestionGroup(questionGroupData);
      }
    })();
  }, [questionGroupId]);

  const { isOpen: isPreviewQuestionsModalOpen, onOpen: openPreviewQuestionsModal, onClose: closePreviewQuestionsModal } = useDisclosure();

  return (
    <Stack spacing={[3, 5]} align="center" pb={["20px", "40px"]}>
      <Center>
        <Heading fontSize={["2xl", "4xl"]} px="10x" pt={["10px", "20px"]}>Create Group</Heading>
      </Center>
      <Center>
        {questionGroup?.name ?
          <Heading fontSize={["1xl", "2xl"]} px="10px" textAlign="center">{questionGroup.name}</Heading>
          :
          <Heading fontSize={["1xl", "2xl"]} px="10px" textAlign="center">&nbsp;</Heading>
        }
      </Center>
      <Center maxWidth={["lg", "xl"]}>
        <Text fontSize={["md", "lg"]} px={["24px", "20px"]}>You don't need to create any prop bets questions, we've already done all of that for you. Just fill out the form and send your unique link to your friends!</Text>
      </Center>
      <Center maxWidth={["lg", "xl"]}>
        <StyledButton
          variant="ghost"
          size={previewQuestionsButtonSize}
          onClick={openPreviewQuestionsModal}
        >
          Preview Questions
        </StyledButton>
        <QuestionGroupPreviewModal
          questionGroup={questionGroup}
          isOpen={isPreviewQuestionsModalOpen}
          onOpen={openPreviewQuestionsModal}
          onClose={closePreviewQuestionsModal}
        />
      </Center>
      <CreateGroupForm
        questionGroupId={params.questionGroupId}
      />
    </Stack>
  );
};
