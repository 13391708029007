import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty, forEach, map, find } from 'lodash';
import {
  Heading, Center, Stack, Text, Box, Flex, Spacer, Spinner, Link
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';

import { fetchQuestionGroupById, fetchQuestionsByQuestionGroupId } from '../../api/Api';
import { IQuestionDict, IQuestionGroup } from '../../interfaces/Models';
import { formatDate } from '../../utils/MiscUtils';
import { StyledIconButton } from '../../styled-components/StyledIconButton';

interface IRouteParams {
  [key: string]: string | undefined;
  questionGroupId: string;
}

export const QuestionGroupPage = () => {
  const params = useParams<IRouteParams>();
  const navigate = useNavigate();

  const questionGroupId = params.questionGroupId;

  const [isLoading, setIsLoading] = useState(false);
  const [questionGroup, setQuestionGroup] = useState<IQuestionGroup>();
  const [questionDict, setQuestionDict] = useState<IQuestionDict>();

  useEffect(() => {
    (async () => {
      if (questionGroupId) {
        setIsLoading(true);
        const { data: questionGroupData, hasError: fetchQuestionGroupError } = await fetchQuestionGroupById(questionGroupId);
        setQuestionGroup(questionGroupData);
        const { data: questionsData, hasError: fetchQuestionsError } = await fetchQuestionsByQuestionGroupId(questionGroupId);
        const questionDict = {};
        forEach(questionsData, (question) => {
          questionDict[question.id] = { ...question };
        })
        setQuestionDict(questionDict);
        setIsLoading(false);
      }
    })();
  }, [questionGroupId, setIsLoading, setQuestionGroup]);

  const onEditClick = (questionId: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const adminApiToken = searchParams.get('adminApiToken');
    const queryParams = adminApiToken ? `?adminApiToken=${adminApiToken}` : '';
    navigate(`/admin/question/${questionId}${queryParams}`);
  }

  return (
    <Center>
      <Stack spacing={2} align="left" px="10px" width="xl">
        <Center>
          <Heading fontSize={["2xl", "4xl"]} px="10x" py={["10px", "20px"]}>Admin Question Group Page</Heading>
        </Center>
        {isLoading &&
          <Center>
            <Spinner
              thickness="5px"
              speed="1s"
              size="xl"
            />
          </Center>
        }
        {!isEmpty(questionGroup) && !isEmpty(questionDict) &&
          <>
            <Box>
              <Center>
                <Link color="brandColorScheme.300" target="_blank" href={`${window.location.origin}/create-group/${questionGroup.id}`}>Create Group Page</Link>
              </Center>
            </Box>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Name
              </Text>
              <Text fontSize={["lg", "xl"]}>
                {questionGroup.name}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Info Text
              </Text>
              <Text fontSize={["lg", "xl"]}>
                {questionGroup.infoText || 'N/A'}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Accept Entries Til
              </Text>
              <Text fontSize={["lg", "xl"]}>
                {formatDate(questionGroup.acceptEntriesTil)}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Questions
              </Text>
              <Box>
              {map(questionGroup.questionIds, (questionId, index) => {
                const question = questionDict[questionId];

                return (
                  <Box py={["2px", "4px"]}>
                    <Flex alignItems="center">
                      <Text fontSize={["lg", "xl"]}>
                        {index + 1}. {question.text}
                      </Text>
                      <Spacer />
                      <StyledIconButton
                        aria-label="edit"
                        icon={<EditIcon />}
                        size="xs"
                        onClick={() => onEditClick(questionId)}
                      />
                    </Flex>
                    {question.choiceResultId &&
                      <Text align="center" fontWeight="bold" fontSize={["lg", "xl"]}>
                        {find(question.choices, { id: question.choiceResultId}).text}
                      </Text>
                    }
                    {question.numberResult &&
                      <Text align="center" fontWeight="bold" fontSize={["lg", "xl"]}>
                        {question.numberResult}
                      </Text>
                    }
                  </Box>
                );
              })}
              </Box>
            </Box>
          </>
        }
      </Stack>
    </Center>
  );
}
