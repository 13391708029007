export enum Environment {
  LOCALHOST = 'localhost',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export const getEnvironment = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'prop-bets-1a472.web.app':
        return Environment.PRODUCTION;
      case 'sbpropbets.com':
        return Environment.PRODUCTION;
      case 'propbets.games': // DEPRECATED
        return Environment.PRODUCTION;
      case 'propclash.com': // Deprecated
        return Environment.PRODUCTION;
      case 'prop-bets-dev.web.app':
        return Environment.DEVELOPMENT;
      case 'localhost':
        return Environment.LOCALHOST;
      default:
        return Environment.DEVELOPMENT;
    }
  }

  console.error('Unable to determine environment because window is undefined.');
  return Environment.DEVELOPMENT;
}
