import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty, map, toString } from 'lodash';
import {
  Heading, Center, Stack, Text, Box, FormControl, FormLabel, useBreakpointValue,
  VStack, FormHelperText, Spinner
} from '@chakra-ui/react';

import { fetchQuestionById, updateQuestion } from '../../api/Api';
import { StyledInput } from '../../styled-components/StyledInput';
import { StyledSelect } from '../../styled-components/StyledSelect';
import { StyledButton } from '../../styled-components/StyledButton';
import { IQuestion, QuestionTypes } from '../../interfaces/Models';
import { numberRegex } from '../../utils/InputUtils';

interface IRouteParams {
  [key: string]: string | undefined;
  questionId: string;
}

export const QuestionPage = () => {
  const params = useParams<IRouteParams>();

  const questionId = params.questionId;

  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState<IQuestion>();
  
  const [apiKey, setApiKey] = useState('');
  const [choiceResultIdInput, setChoiceResultIdInput] = useState<string>('');
  const [numberResultInput, setNumberResultInput] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);

  const inputSize = useBreakpointValue(["md", "lg"]);

  useEffect(() => {
    const fetchData = async () => {
      if (questionId) {
        const searchParams = new URLSearchParams(window.location.search);
        const adminApiToken = searchParams.get('adminApiToken');
        if (adminApiToken) {
          setApiKey(adminApiToken);
        }
        
        setIsLoading(true);
        const { data: questionData, hasError: fetchQuestionError } = await fetchQuestionById(questionId);
        setQuestion(questionData);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [questionId, setIsLoading]);

  useEffect(() => {
    if (!isEmpty(question)) {
      setChoiceResultIdInput(question?.choiceResultId);
      setNumberResultInput(toString(question?.numberResult));
    }
  }, [question, setChoiceResultIdInput, setNumberResultInput])

  const onUpdateQuestionClick = async () => {
    setIsSubmitting(true);

    const dataToUpdate: Partial<IQuestion> = {};

    if (question.type === QuestionTypes.Choice) {
      dataToUpdate.choiceResultId = choiceResultIdInput || null;
    }

    if (question.type === QuestionTypes.Number) {
      const newNumberResult = parseFloat(numberResultInput);
      dataToUpdate.numberResult = newNumberResult || null;
    }

    const {
      data: updatedQuestionData,
      hasError: updateQuestionError
    } = await updateQuestion(questionId, dataToUpdate, apiKey);

    if (updateQuestionError) {
      setHasError(true);
      setErrorText('There was an error updating the question. Please try again.');
      setIsSubmitting(false);
      return;
    }

    setQuestion(updatedQuestionData);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (isEmpty(question) || !apiKey) {
      setIsUpdateDisabled(true);
      return;
    }

    if (question.type === QuestionTypes.Choice) {
      setIsUpdateDisabled(toString(question.choiceResultId) === toString(choiceResultIdInput));
    }

    if (question.type === QuestionTypes.Number) {
      setIsUpdateDisabled(question.numberResult === parseFloat(numberResultInput));
    }
  }, [question, apiKey, choiceResultIdInput, numberResultInput, setIsUpdateDisabled]);

  return (
    <Center>
      <Stack spacing={2} align="left" px="10px" width="xl">
        <Center>
          <Heading fontSize={["2xl", "4xl"]} px="10x" py={["10px", "20px"]}>Admin Question Page</Heading>
        </Center>
        {isLoading &&
          <Center>
            <Spinner
              thickness="5px"
              speed="1s"
              size="xl"
            />
          </Center>
        }
        {!isEmpty(question) &&
          <>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Question Text
              </Text>
              <Text fontSize={["lg", "xl"]}>
                {question.text}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Title
              </Text>
              <Text fontSize={["lg", "xl"]}>
                {question.title || 'N/A'}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Help Text
              </Text>
              <Text fontSize={["lg", "xl"]}>
                {question.helpText || 'N/A'}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Question Type
              </Text>
              <Text fontSize={["lg", "xl"]}>
                {question.type}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                Multiplier
              </Text>
              <Text fontSize={["lg", "xl"]}>
                {question.multiplier || 1}
              </Text>
            </Box>
            {question.type === QuestionTypes.Choice &&
              <Box>
                <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                  Choices
                </Text>
                {map(question.choices, (choice) => {
                  return (
                    <Text fontSize={["lg", "xl"]}>
                      {choice.text} {choice.id === question.choiceResultId && '✅'}
                    </Text>
                  )
                })}
              </Box>
            }
            {question.type === QuestionTypes.Number &&
              <>
                <Box>
                  <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                    Is Tie-Breaker?
                  </Text>
                  <Text fontSize={["lg", "xl"]}>
                    {question.isTieBreaker ? 'Yes' : 'No'}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="brandColorScheme.300" fontSize={["lg", "xl"]}>
                    Number Result
                  </Text>
                  <Text fontSize={["lg", "xl"]}>
                    {question.numberResult || 'N/A'}
                  </Text>
                </Box>
              </>
            }
            <Box height={["10px", "20px"]}></Box>
            <Stack spacing={3} align="center" maxWidth="xl">
              <FormControl maxWidth="lg" px="10px" isRequired>
                <FormLabel>API Key</FormLabel>
                <StyledInput
                  value={apiKey}
                  onChange={(event) => setApiKey(event.target.value)}
                  size={inputSize}
                  fontWeight="normal"
                  maxWidth="lg"
                  colorScheme="gray"
                  variant="filled"
                  />
              </FormControl>
              {question.type === QuestionTypes.Choice &&
                <FormControl maxWidth="lg" px="10px">
                  <FormLabel>Choice Result</FormLabel>
                  <StyledSelect
                    value={choiceResultIdInput}
                    onChange={(event) => setChoiceResultIdInput(event.target.value)}
                    size={inputSize}
                    fontWeight="normal"
                    maxWidth="lg"
                    colorScheme="gray"
                    variant="filled"
                    placeholder=" "
                    >
                    {map(question.choices, (choice) => {
                      return (
                        <option key={choice.id} value={choice.id}>{choice.text}</option>
                        );
                      })}
                  </StyledSelect>
                </FormControl>
              }
              {question.type === QuestionTypes.Number &&
                <FormControl maxWidth="lg" px="10px">
                  <FormLabel>Number Result</FormLabel>
                  <StyledInput
                    colorScheme="gray"
                    size={inputSize}
                    variant="filled"
                    type="number"
                    pattern={numberRegex.source}
                    value={numberResultInput}
                    onChange={(event) => setNumberResultInput(event.target.value)}
                    />
                </FormControl>
              }
              <FormControl pt={["10px", "20px"]} px="10px">
                <VStack>
                  <StyledButton
                    size={inputSize}
                    isDisabled={isUpdateDisabled}
                    isLoading={isSubmitting}
                    loadingText={"Updating Question"}
                    onClick={onUpdateQuestionClick}
                    >
                    Update Question
                  </StyledButton>
                  {hasError &&
                    <FormHelperText px="10px" color="red.300">
                      {errorText}
                    </FormHelperText>
                  }
                </VStack>
              </FormControl>
            </Stack>

          </>
        }
      </Stack>
    </Center>
  );
};
