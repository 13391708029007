import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Text, useBreakpointValue, Center, useDisclosure, Box
} from '@chakra-ui/react';

import { QuestionGroupPreviewModal } from '../components/QuestionGroupPreviewModal';
import { StyledButton } from '../styled-components/StyledButton';
import { IQuestionGroup } from '../interfaces/Models';
import { formatDate } from '../utils/MiscUtils';


interface IProps {
  questionGroup: IQuestionGroup;
}

export const QuestionGroupCard = (props: IProps) => {
  const { questionGroup } = props;

  const navigate = useNavigate();

  const entryButtonSize = useBreakpointValue(["sm", "md"]);
  const previewQuestionsButtonSize = useBreakpointValue(["xs", "sm"]);

  const { isOpen: isPreviewQuestionsModalOpen, onOpen: openPreviewQuestionsModal, onClose: closePreviewQuestionsModal } = useDisclosure();

  const onCreateGroupClick = () => {
    navigate(`/create-group/${questionGroup.id}`);
  };

  return (
    <Box
      borderWidth="4px"
      borderRadius="lg"
      px={["10px", "10px"]}
      paddingTop={["0px", "4px"]}
      paddingBottom={["12px", "16px"]}
      width={["300px", "300px"]}
    >
      <Text
        fontSize={["md", "lg"]}
        maxWidth={["mg", "xl"]}
        paddingTop="10px"
        align="center"
        fontWeight="bold"
      >
        {questionGroup.name}
      </Text>
      <Text
        fontSize={["sm", "md"]}
        maxWidth={["mg", "xl"]}
        paddingBottom="10px"
        align="center"
      >
        {formatDate(questionGroup.acceptEntriesTil)}
      </Text>
      <Center>
        <StyledButton
          colorScheme="brandColorScheme"
          size={entryButtonSize}
          onClick={onCreateGroupClick}
        >
          Create New Group 🖋
        </StyledButton>
      </Center>
      <Center pt={["8px", "10px"]}>
        <StyledButton
          size={previewQuestionsButtonSize}
          onClick={openPreviewQuestionsModal}
        >
          Preview Questions
        </StyledButton>
        <QuestionGroupPreviewModal
          questionGroup={questionGroup}
          isOpen={isPreviewQuestionsModalOpen}
          onOpen={openPreviewQuestionsModal}
          onClose={closePreviewQuestionsModal}
          primaryButtonText="Create Group"
          onPrimaryButtonClick={onCreateGroupClick}
        />
      </Center>
    </Box>
  );
}
