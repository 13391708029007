import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import { createRoot } from 'react-dom/client'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { getEnvironment, Environment } from './utils/Environment';
import { isEmpty } from "@chakra-ui/utils";

// Initialize Firebase first
let firebaseConfig = {};
switch (getEnvironment()) {
  case Environment.PRODUCTION:
    firebaseConfig = {
      apiKey: "AIzaSyCRjmIdMuH1JL9G39Mk9frSDsCnckompC8",
      authDomain: "prop-bets-1a472.firebaseapp.com",
      projectId: "prop-bets-1a472",
      storageBucket: "prop-bets-1a472.appspot.com",
      messagingSenderId: "521097191730",
      appId: "1:521097191730:web:53894586bef392b3b6772b",
      measurementId: "G-X0JDBK4RYX"
    };
    break;
  case Environment.DEVELOPMENT:
    firebaseConfig = {
      apiKey: "AIzaSyCosmAtflzHX53o7AfwlHonaAUiFhk4jxo",
      authDomain: "prop-bets-dev.firebaseapp.com",
      projectId: "prop-bets-dev",
      storageBucket: "prop-bets-dev.appspot.com",
      messagingSenderId: "1082475171134",
      appId: "1:1082475171134:web:474d3d2d362e60f79af06f",
      measurementId: "G-TCKX7VEWZ3"
    };
    break;
  default:
    break;
}

// Initialize Firebase before rendering
if (!isEmpty(firebaseConfig)) {
  try {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  } catch (error) {
    console.error('Firebase initialization error:', error);
  }
}

// Then render React
const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={"dark"}/>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
